import { VisuallyHidden } from '@overdose/components'
import { IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import Link from 'next/link'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './ProjectCard.module.css'
import { ProjectCardProps } from './ProjectCard.types'

export const ProjectCard = ({
  image,
  title,
  url,
  backgroundColor,
  imageFit,
  isMobileCarouselDisabled,
}: ProjectCardProps) => {
  const isBackGroundColorLight = backgroundColor === 'light'

  return (
    <div
      className={classNames(
        backgroundColor === 'dark' ? 'bg-background-thumb' : 'bg-white',
        styles.card,
        'p-4 md:p-6 relative rounded-md overflow-hidden',
        isMobileCarouselDisabled && styles.mobileCarouselDisabledCard
      )}>
      {image && (
        <Image
          title={title}
          src={getImageSrc(image.src, '350')}
          addSrcSet={false}
          alt={image.altText}
          width={image.width}
          height={image.height}
          className={classNames(
            imageFit === 'cover'
              ? 'object-cover absolute inset-0 h-full w-full'
              : 'pb-5 object-contain w-full'
          )}
        />
      )}
      <div
        className={classNames(
          'flex w-full justify-between items-center',
          imageFit === 'cover'
            ? 'absolute md:bottom-8 bottom-4 left-0 md:px-6 px-4'
            : ''
        )}>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.Heading5}
          className={classNames(
            isBackGroundColorLight ? styles.title : '!text-white',
            'tracking-little'
          )}>
          {title}
        </Typography>
        <Link
          href={url}
          className={classNames(
            'rounded-full md:w-12 w-10 md:h-12 h-10 flex justify-center items-center',
            isBackGroundColorLight ? 'bg-white' : 'bg-primary-blue'
          )}>
          <IconArrowRight
            size={18}
            color={
              isBackGroundColorLight
                ? 'var(--btn-solid-color-text-secondary)'
                : 'var(--color-grayscale-white)'
            }
            strokeWidth={3}
          />
          <VisuallyHidden>{title}</VisuallyHidden>
        </Link>
      </div>
    </div>
  )
}
