import { VisuallyHidden } from '@overdose/components'
import { Button } from '~/components/Button'

export const NavigationButton = ({ onClick, icon, disabled, label }) => {
  return (
    <Button
      iconOnly
      icon={icon}
      onClick={onClick}
      shape='circle'
      status='accent'
      disabled={disabled}
      style={{
        '--btn-solid-color-bg-accent': 'var(--greyscale-200)',
        '--btn-icon-size': '16px',
        '--btn-size-md-height': '32px',
        '--btn-size-md-width': '32px',
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
    </Button>
  )
}
