import { Image, SectionProps } from 'shared-types'
import { CategoryCardProps } from '~/components/CategoryCard/CategoryCard.types'
import { ProductCardProps } from '~/components/ProductCard/ProductCard.types'
import { ProjectCardProps } from '~/components/ProjectCard/ProjectCard.types'

export enum CardTypes {
  CATEGORY = 'category-card',
  PROJECT = 'project-card',
  PRODUCT = 'product-card',
}

export type Card =
  | ({
      cardType: CardTypes.CATEGORY
    } & CategoryCardProps)
  | ({
      cardType: CardTypes.PROJECT
    } & ProjectCardProps)
  | ({
      cardType: CardTypes.PRODUCT
    } & ProductCardProps)

export interface ICards extends SectionProps {
  title: string
  paragraph: string
  seeMoreText: string
  seeMoreUrl: string
  cards: Card[]
  backgroundColor: 'light' | 'dark' | `#${string}`
  image?: Image
  isDesktopCarouselDisabled?: boolean
  isMobileCarouselDisabled?: boolean
  rowItemCountOnDesktop?: number
  rowItemCountOnMobile?: number
  categorySku?: string
  marketingTile?: {
    desktop: Image
    tablet: Image
    mobile: Image
  }
  marketingTileAtStart?: boolean
  marketingTileAtEnd?: boolean
}
